.ficha {

    hr {
        border-top: 1px solid #91AAAB;
    }

    h1 {
        font-weight: 900;
        margin-bottom: 0;
        color: #545C5D !important;

        i {
            font-size: 1.4rem;

            &.fa-venus {
                color: #FFB1CB;
            }
            &.fa-mars {
                color: #01A6EA;
            }
            &.fa-circle-question {
                color: #AE8A8C;
            }
        }
    }

    h2 {
        color: $miwuki;
        font-weight: 700;
        font-size: 1.4rem;
    }

    h3 {
        font-weight: 400;
        font-size: 1rem;
    }

    img {
        border-radius: 1.2rem;
        width: 100%;
    }

    // Fotos

    .fotos {
        cursor: pointer;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;

        img {
            border-radius: 0.65rem;
        }

        .galeria {
            background-color: rgba(225, 234, 238, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            color: $miwuki;
            border-radius: 0.65rem;
        }
    }

    .info-mascota {
        display: flex;
        justify-content: space-between;
    }

    // Info
    .info {
        display: flex !important;
        flex-wrap: wrap !important;
        font-weight: 500 !important;
        font-size: 1.1rem;

        .info-item {
            display: flex !important;
            align-items: center !important;
            color: #BBCDCE!important;

            i {
                margin-right: 0.5rem;
            }
        }
    }

    // Info
    .actions {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        & > span {
            display: flex;
            flex-flow: row nowrap;
            gap: 1rem;

            div, & > a {
                flex-grow: 1;

                padding: 0.3rem;
                border-radius: 2rem;
                background-color: #91AAAB;
                text-decoration: none;

                &.whatsapp-btn {
                    background-color: #25d366 !important;
                }

                &.facebook-btn {
                    background-color: #1877f2 !important;
                }

                &.twitter-btn {
                    background-color: #1da1f2 !important;
                }

                &.whatsapp-btn, &.facebook-btn, &.twitter-btn {
                    border: none !important;
                    padding: 0.3rem;

                    & > a {
                        background-color: none !important;
                        border-radius: none !important;
                        color: $blanco !important;

                        & > i {
                            font-size: 1.2rem;
                            background-color: none !important;
                            border-radius: 50%;
                            color: $blanco;
                            height: 28px;
                            width: 28px;
                            line-height: 28px;
                            text-align: center;
                        }
                    }
                }

                &.fav {
                    background-color: #ffca28 !important;

                    i {
                        color: #ffca28 !important;
                    }
                }

                &:nth-child(2) {
                    background-color: $miwuki;
                }

                span {
                    font-weight: 700;
                    color: $blanco;
                    padding-left: 0.7rem;
                    padding-right: 1rem;
                }

                & > i {
                    background-color: $blanco;
                    border-radius: 50%;
                    color: #91AAAB;
                    height: 36px;
                    width: 36px;
                    line-height: 36px;
                    text-align: center;
                }
            }
        }
    }

    .contenido {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        & > div:nth-child(1) {
            flex: 1;
        }
    }

    // Owner
    .owner {
        display: flex;
        flex-direction: column;
        border-radius: 0.65rem;
        min-width: 300px;

        .nombre {
            display: flex;
            justify-content: space-between;
            align-items:center;
            border: 1px solid rgba(225, 234, 238, 0.3);
            padding: 0.85rem;
            width: 100%;

            h1 {
                font-size: 1.2rem;
                font-weight: 700;

                span {
                    font-size: 1rem;
                    color: #BBCDCE !important;
                    display: block;

                    i {
                        color: cornflowerblue;
                        font-size: 0.6rem;
                    }
                }
            }

            img {
                width: 60px !important;
                border-radius: 50%;
                margin-left: 1rem;
            }
        }

        .owner-time {
            padding: 0.85rem;
            border-radius: 0 0 0.65rem 0.65rem;
            background-color: rgba(225, 234, 238, 0.3);
            font-weight: 500;
            align-items: center;

            a {
                color: #91AAAB;
                display: flex;
                justify-content: space-between;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    color: $miwuki;
                }
            }

            i {
                font-size: 1rem;
            }

            &>i {
                color: $miwuki;
            }

            span>i {
                font-size: 1rem;
                margin-right: 0.2rem;
            }
        }
    }

    // Entrega
    .entrega {
        list-style: none;

        i {
            margin-right: 0.5rem;
            color: #8BC34A;

            &.fa-circle-exclamation {
                color: #ffca28;
            }
        }
    }

    .pills {
        span {
            background-color: $miwuki;
            display: inline-block;
            padding: 0.425rem 0.6rem;
            font-size: 0.85rem;
            font-weight: 600;
            line-height: 1;
            color: $blanco;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: 0.65rem;
            margin-bottom: 0.5rem;
        }
    }

    // Tasa
    .conjunta {
        padding: 0.85rem;
        border-radius: 0.65rem;
        background-color: rgba(225, 234, 238, 0.3);
        width: auto;
        margin-left: auto;
        text-align: end;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        span {
            display: block;
            font-weight: 700;

            &.nombre {
                color: $miwuki;
                font-size: 1.6rem;
            }
        }

        img {
            width: 60px;
            margin-left: 1rem;
        }
    }

    // Tasa
    .tasa {
        padding: 0.85rem;
        border-radius: 0.65rem;
        background-color: rgba(225, 234, 238, 0.3);
        width: auto;
        margin-left: auto;
        text-align: end;
        cursor: pointer;

        span {
            display: block;
            font-weight: 700;

            i {
                color: deepskyblue;
                font-size: 1.1rem;
            }

            &.precio {
                color: $miwuki;
                font-size: 1.6rem;
            }
        }

        #info-tasa {
            text-align: left;
            font-size: 0.75rem;
            color: #91AAAB;

            &>div {
                &:first-child {
                    font-style: normal !important;
                    color: $miwuki;
                    margin-top: 0 !important;
                    margin-bottom: 0.5rem;
                }

                font-weight: bold;
                margin-top: 0.85rem;
            }
        }
    }

    .detalles {
        div {
            font-size: 1.1rem;
            margin-bottom: 0.75rem;
            line-height: 1.15rem;

            & >span {
                font-size: 0.8rem;
                display: block;
                font-weight: 700;
                color: #91AAAB;
            }
        }
    }

    .casos {
        & > div {
            margin-bottom: 1.5rem;
            &:nth-last-child(-n+6) { margin-bottom: 0; }
        }
    }

    .caso {
        img {
            border-radius: 0rem;
        }
    }

    .tipo-protectora {
        padding: 0.23rem 0.6rem;
        margin-right: 0.65rem;
        border-radius: 0.3rem;
        color: $blanco;
        font-size: 0.85rem;

        &.p1 { background-color: #55CBCD; }
        &.p2 { background-color: #FFB8B1; }
        &.p3 { background-color: #26A69A; }
        &.p4 { background-color: #7986CB; }
        &.p5 { background-color: #42A5F5; }
    }
}

@media only screen and (max-width: 768px)
{
    .actions {
        flex-direction: column-reverse !important;

        & > span {
            flex-flow: row nowrap !important;
            gap: 1rem;
        }
    }
    .contenido {
        flex-direction: column !important;
    }
    .info-mascota {
        flex-direction: column;

        &>div {
            margin-bottom: 1.45rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .actions {
            display: flex;
            gap: 1rem;

            button {
                flex-grow: 1;
            }
        }
    }
}
