// Body
$body-bg: #f8fcff;

// Typography
$font-family: 'Nunito', sans-serif;
$font-size-base: 0.9em;
$line-height-base: 1.6;

// Bootstrap Base colors
$white:      #ffffff !default;
$black:      #000000 !default;
$gray-100:   #F5F8FA !default;
$gray-200:   #EFF2F5 !default;
$gray-300:   #E4E6EF !default;
$gray-400:   #B5B5C3 !default;
$gray-500:   #A1A5B7 !default;
$gray-600:   #7E8299 !default;
$gray-700:   #5E6278 !default;
$gray-800:   #3F4254 !default;
$gray-900:   #181C32 !default;
$text-muted: $gray-500 !default;

// Miwuki Base
$miwuki:       #4db8c0;
$miwuki-dark:  #118a95;

// Affinity Base
$affinity: #DC4814;

$naranja: #f8c960;
$blanco : #ffffff;

$font-color: #545C5D;

$primary: $miwuki;
$warning: $naranja;

// Brand colors
$facebook: #4267B2;
$google: #DB4437;

// Main
$bg-main: #f8fcff;

:root {
    --main-color: #3d5af1;
    --main-color-dark: #3651d4;
    --main-color-light: #5872f5;
    --text-color: #cfcde7;
}

// Grid
$bs-gutter-x: 2em;
$bs-gutter-y: 0;
