.form-label {
    margin-bottom: 0.65rem;
    font-size: 1.02rem;
    font-weight: 500;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #5E6278;
    background-clip: padding-box;
    border: 1px solid #F5F9FA;
    appearance: none;
    border-radius: 0.475rem;
    box-shadow: none !important;
    transition: color 0.2s ease, background-color 0.2s ease;
    background-color: #F5F9FA;

    &:focus {
        background-color: #fff;
        border-color: $miwuki !important;
        outline: 0 !important;
        box-shadow: 0 0 0 .25rem rgba(77,184,192,.25) !important;
    }
}

.form-select {
    display: block;
    width: 100%;
    padding: 0.75rem 3rem 0.75rem 1rem;
    -moz-padding-start: calc(1rem - 3px);
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #5E6278 !important;
    background-color: #F5F9FA !important;
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 16px 12px;
    border: 1px solid #F5F9FA !important;
    border-radius: 0.475rem;
    box-shadow: none !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
        border-color: $miwuki !important;
        outline: 0 !important;
        box-shadow: 0 0 0 .25rem rgba(77,184,192,.25) !important;
    }
}

.form-switch {
    &.unlabeled {
        .form-check-input {
            margin-left: 0 !important;
        }
    }
}

// @media only screen and (max-width: 768px)
// {
//     .form-label {
//         margin-top: 1rem !important;
//     }
// }
