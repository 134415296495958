.login {
    display: flex;
    flex-direction: row !important;
    flex-grow: 1;
    height: 100%;

    a {
        text-decoration: none !important;
        color: $miwuki;

        &:hover {
            color: $miwuki-dark;
        }
    }

    & > div {
        flex: 1;
        align-items: stretch;
        padding: 2rem;
    }

    & > div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $miwuki;
        background-image: url('/img/huellas.png');

        .wrapper {
            background-color: $blanco;
            width: 500px !important;
            padding: 2rem;
            border-radius: 2rem;
            filter: drop-shadow(0px 8px 8px rgba(17, 138, 149, 0.3));
        }
    }

    & > div:nth-child(2){
        background-color: $blanco;
        background-image: url('/img/img-bg.jpg');
        background-size: cover;
        background-position: center;
    }

    .btn-rrss {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        a {
            flex: 1;
        }
    }
}

@media only screen and (max-width: 768px)
{
    .login {
        flex-direction: column !important;

        & > div:nth-child(1) {
            order: 2 !important;
        }

        & > div:nth-child(2) {
            order: 1 !important;
            max-height: 220px !important;
        }


        .btn-rrss {
            flex-direction: column !important;

            button {
                width: 100% !important;
            }
        }
    }
}
