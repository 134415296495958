.carrousel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;
    gap: 1em;
    height: 250px;

    .button {
        display: flex;
        align-items: center;
        font-size: 2em;
        color: #4db8c0;
        cursor: pointer
    }

    .casos {
        display: flex;
        flex-flow: row wrap;
        gap: 1em;
        justify-content: space-between;
        overflow: hidden;

        a {
            max-width: 160px;
            display: block;
            text-decoration: none;
            color: inherit;
            font-size: inherit;
        }

        .caso {
            border-radius: 1.2rem;
            overflow: hidden;
            background-color: white;
            filter: drop-shadow(0px 5px 5px rgba(225, 234, 238, 0.5));

            & > img {
                width: 160px;
                height: 160px;
            }

            .info {
                padding: 8px;
                text-align: center;

                .nombre {
                    max-width: 100%;
                    font-weight: 600;
                    font-size: 1.05rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .location {
                    img {
                        width: 15px;
                        vertical-align: middle;
                    }

                    span {
                        font-weight: 400;
                        font-size: 0.8rem;
                        color: #BBCDCE;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        .casos {
            justify-content: center;
        }
    }
}
