.protectoras {
    & > div {
        margin-bottom: 1.5rem;
        &:nth-last-child(-n+3) { margin-bottom: 0; }
    }
}

.protectora {
    background-color: $blanco;
    border-radius: 1.2rem;
    filter: drop-shadow(0px 8px 8px rgba(225, 234, 238, 0.5));
    width: 100%;
    max-height: 150px;
    overflow: hidden;

    & > a {
        text-decoration: none;
        color: $font-color;
        display: flex;

        & > img {
            height: 150px;
        }
    }

    .info {
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;

        & > .nombre {
            font-weight: 600;
            font-size: 1.15rem;
            line-height: 1.5rem;
            overflow: hidden;
            max-width: 100%;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
        }

        & > .location {
            display: flex !important;
            align-items: center !important;
            color: #BBCDCE!important;
            margin-top: 0.25rem;

            i {
                margin-right: 0.3rem;
                font-size: 0.9rem;
            }

            span.bandera > img {
                width: 21px;
                margin-left: 0.5rem;
            }
        }

        & > .extra {
            margin-top: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > i {
                color: $miwuki;
            }
        }
    }
}

@media only screen and (max-width: 768px)
{
    .protectoras {
        & > div {
            &:nth-last-child(-n+3) { margin-bottom: 1.5rem !important; }
            &:last-child { margin-bottom: 0 !important; }
        }
    }

    .protectora {
        height: 100px;

        & > a {
            & > img {
                height: 100px !important;
            }
        }

        .info {
            padding: 0.6rem 0.85rem ;
        }
    }
}
