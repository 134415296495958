*, *:before, *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    height:100%;
    min-height: 100vh;
}

body {
    display: flex;
    background-color: $body-bg;
    color: $font-color;
    font-family: $font-family;
    font-size: 14px !important;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    text-rendering: auto;
    flex-direction: column;
    min-height:100%;

    > .content {
        background-color: $miwuki;
    }

    & > div {
        flex-grow: 1;
    }
}

.icon::before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}

img, svg {
    vertical-align: middle;
}

img {
    max-inline-size: 100%;
    block-size: auto;
}

ul {
    margin: 0;
    padding: 0;
    margin-bottom: 0;

    list-style-position: inside;
}

.container {
    max-width: 1320px;
    padding: 0;
}

.main-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    margin-top: -2.5rem;
    flex: 1;

    .container {
        background-color: $blanco;
        border-radius: 2rem 2rem 0px 0px;
        padding: 2rem;
        min-height: 100%;
    }

    &:before {
        // filter: drop-shadow(0px -8px 8px rgba(225, 234, 238, 0.5));
        content: "";
        box-shadow: 0 0.2rem 0.25rem rgba(0, 0, 0, 0.2);
        // filter: drop-shadow(0px -8px 8px red);
    }
}

@media only screen and (max-width: 768px)
{
    main {
        & > .container {
            padding: 1.5rem;
        }
    }
}

@import 'miwuki/components/fonts';
@import 'miwuki/components/forms';
@import 'miwuki/components/buttons';

// Miwuki
@import 'miwuki/varios';
@import 'miwuki/nav';
@import 'miwuki/footer';
@import 'miwuki/caso';
@import 'miwuki/mis-casos';
@import 'miwuki/protectora';
@import 'miwuki/perfil';
@import 'miwuki/ficha';
@import 'miwuki/login';
@import 'miwuki/match';
@import 'miwuki/carrousel';


.caso-pic {
    position: relative;
    cursor: pointer;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
    }
}

.btn-label {
    position: relative;
    left: -12px;
    display: inline-block;
    padding: 6px 12px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 3px 0 0 3px;
}

.btn-labeled {
    padding-top: 0;
    padding-bottom: 0;
}

.dot-number {
    -moz-border-radius: 50%;
    background-color: $miwuki;
    border-radius: 50%;
    color: $blanco;
    display: inline-block;
    font-size: 0.65rem;
    font-weight: 600;
    height: 25px;
    line-height: 25px;
    margin-right: 0.45rem;
    text-align: center;
    width: 25px;
}
