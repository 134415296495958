.nav {
    display: flex;
    justify-content: space-between;

    .topbar {
        background-color: $miwuki;
        padding: 2rem;
        width: 100%;
    }

    #toggle-perfil, #nav-perfil {
        display: none;
    }

    .menu {
        background-color: $miwuki-dark;
        padding: 2rem;
        width: 100%;
        font-size: 1.1rem;
        // background-image: url('/img/huellas.png');

        .container {
            padding-bottom: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;

            & > i {
                color: $blanco;
                cursor: pointer;
                font-size: 1.7rem;
                font-weight: 700;

                &:hover {
                    color: $gray-300;
                }
            }
        }

        nav {
            flex: 1;

            ul {
                display: flex;
                list-style: none;
                color: $blanco;

                li {
                    align-items: center;
                    cursor: pointer;
                    outline: none !important;
                    transition: none;
                    margin-right: 0.5rem;

                    a {
                        border-radius: 0.475rem;
                        padding: 0.65rem 1rem;
                        color: $blanco;
                        text-decoration: none !important;

                        &.active {
                            background-color: $miwuki;
                        }

                        &:hover {
                            background-color: $miwuki;
                        }
                    }
                }
            }
        }
    }

    .account {
        display: flex;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: $blanco;
            font-size: 0.9rem;
            margin-left: 24px;

            i {
                margin-right: 10px;

                &.logout {
                    font-size: 1.3rem;
                }
            }

            span {
                font-size: 1.15rem;
                font-weight: 600;
            }

            img {
                vertical-align: middle;
                width: 36px;
                height: 36px;
                border-radius: 50%;
                margin-left: 12px;
                border: 2px solid $blanco;
            }

            &.active {
                color: $miwuki-dark;
            }

            &:hover {
                color: $miwuki-dark;
            }

            &.register {
                font-weight: 600;
                background-color: $blanco;
                color: $miwuki;
                padding: 0.5rem 1rem;
                border-radius: 2rem;
            }
        }
    }

    .miwuki-logo {
        .logo-sm {
            display: none;
        }
    }

    .menu-mobile {
        display: none;
    }

    .search-box {
        width: 275px;
        height: 44.78px;
        background: white;
        border-radius: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        filter: drop-shadow(0px 5px 4px rgb(12, 103, 111, 0.25));
        cursor: pointer;

        &:hover {
            filter: drop-shadow(0px 0px 0px);
        }

        span {
            box-sizing: border-box;
            padding: 1rem;
            color: #BBCDCE;
        }

        i {
            background-color: $miwuki;
            border-radius: 50%;
            color: white;
            height: 44.78px;
            width: 44.78px;
            line-height: 40px;
            text-align: center;
            border: 3px solid white;
        }
    }
}

@media only screen and (max-width: 768px)
{
    .topbar {
        padding: 1.2rem !important;
    }

    #toggle-perfil {
        display: block !important;
    }

    nav, .search-box, .info-estados, #nav-perfil {
        display: none !important;
    }

    .menu-mobile {
        display: flex !important;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        i {
            height: 25px !important;
            font-size: 1.5rem;
            margin-left: 1.5rem;
            color: $blanco;
        }
    }

    .nav {
        .menu {
            .container {
                padding-bottom: 0 !important;
            }
        }
    }

    .account {
        display: none !important;
    }

    // .search-box {
    //     width: 100% !important;
    // }

    .miwuki-logo{
        .logo-xl {
            display: none !important;
        }
        .logo-sm {
            display: block !important;
            height: 44px;
        }
    }

    nav {
        padding-bottom: 2.5rem;
        width: 100%;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
                margin-right: 0 !important;
                width: 100%;
                text-align: center;

                a {
                    display: block;
                    color: $blanco;
                    text-decoration: none !important;
                    border-radius: 0.475rem;
                    padding: 0.65rem 1rem;

                    &.active {
                        background-color: $miwuki;
                        border-radius: 0.475rem;
                    }

                    &:hover {
                        background-color: $miwuki;
                        border-radius: 0.475rem;
                    }
                }
            }
        }
    }
}
