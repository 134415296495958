.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: $miwuki !important;
    border-color: $miwuki;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visite {
    background-color: $miwuki !important;
    border-color: $miwuki;
}

.mw-btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.4rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.475rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-miwuki, .btn-miwuki:hover, .btn-miwuki:active, .btn-miwuki:visited {
    background-color: $miwuki !important;
    border-color: $miwuki;
    color: $blanco !important;
}
.btn-fb, .btn-fb:hover, .btn-fb:active, .btn-fb:visited {
    background-color: $facebook !important;
    border-color: $facebook;
    color: $blanco !important;
}
.btn-go, .btn-go:hover, .btn-go:active, .btn-go:visited {
    background-color: $google !important;
    border-color: $google;
    color: $blanco !important;
}
