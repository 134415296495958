footer {
    color: #c1c7c9;

    h2 {
        color: $miwuki;
        font-size: 1.35rem;
        font-weight: bold;
    }

    h3 {
        font-size: 1.1rem;
        color: $blanco;
    }

    .footer {
        padding: 2rem;
        background-color: #272E43;

        .logo {
            height: 150px;
        }
    }

    .copy {
        padding: 1rem 2rem;
        background-color: #1f2536;

        .container {
            display: flex;
            justify-content: space-between;
        }

        .links ul {
            display: flex;
            justify-content: space-between;
            margin: 0;
            list-style: none;
            align-items: center;

            li {
                margin-left: 1rem;
            }
        }
    }

    a {
        color: #f2f3f4;
        text-decoration: none;
        font-weight: 600;

        &:hover {
            color: $miwuki;
        }

        &.affinity:hover {
            color: $affinity;
        }
    }

    p {
        margin-bottom: 0;
    }

    .links {
        a {
            display: block;
            font-weight: 400;
        }
    }

    .logos {
        text-align: end;
    }
}

@media only screen and (max-width: 768px) {
    .logos {
        margin-top: 1.5rem;
        text-align: center !important;
    }

    .copy {
        .container {
            flex-direction: column !important;

            .legal, .links {
                text-align: center !important;
            }

            .legal {
                margin-bottom: 0.85rem;
            }

            .links {
                ul {
                    flex-direction: column !important;
                    justify-content: center !important;
                }
            }
        }
    }
}
