// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700;800;900&display=swap');

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Variables
@import 'variables';

// Miwuki
@import 'miwuki';
