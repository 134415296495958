.casos {
    & > div {
        margin-bottom: 1.5rem;
        &:nth-last-child(-n+6) { margin-bottom: 0; }
    }
}

.caso {
    background-color: $blanco;
    border-radius: 1.2rem;
    filter: drop-shadow(0px 8px 8px rgba(225, 234, 238, 0.5));
    overflow: hidden;
    width: 100%;

    & > a {
        text-decoration: none;
        color: $font-color;
    }

    .info {
        position: relative;
    }

    .estado {
        position: absolute;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        top: 12px;
        left: 12px;
        border: 2px solid $blanco;

        &.e1 { background-color: #8BC34A; }
        &.e2 { background-color: #F44336; }
        &.e3 { background-color: #03A9F4; }
        &.e4 { background-color: #BA68C8; }
        &.e5 { background-color: #FFA726; }
        &.e6 { background-color: #009688; }
        &.e7 { background-color: #607D8B; }
        &.e8 { background-color: #263238; }
        &.e9 { background-color: #CFD8DC; }
        &.e10 { background-color: #FFDE03; }
    }

    .tipo {
        position: absolute;
        top: 12px;
        right: 12px;
        color: $blanco;
        font-size: 1.15rem;
    }

    .views {
        position: absolute;
        bottom: 12px;
        left: 12px;
        color: $blanco;
    }

    .foto {
        width: 100%;
        border-radius: 0px;

        img {
            width: 100%;
            min-width: 100%;
            height: auto;
        }
    }

    .nombre {
        width: 100%;
        padding: 8px 16px;
        font-weight: 600;
        text-align: center;
        font-size: 1.15rem;
        line-height: 1.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        background: #FFFFFF;
        border-radius: 0px;

        img {
            width: 14px;
            vertical-align: middle;
        }

        span {
            font-weight: 400;
            font-size: 0.8rem;
            color: #BBCDCE;
        }
    }
}

@media only screen and (max-width: 768px)
{
    .casos {
        & > div {
            margin-bottom: 1.5rem;
            &:nth-last-child(-n+6) { margin-bottom: 1.5rem; }
            &:nth-last-child(-n+2) { margin-bottom: 0rem; }
        }
    }
}
