.perfil
{
    display: flex;
    flex-direction: row;
    gap: 2rem;

    &>div {
        flex-grow: 2;
    }

    & > nav {
        min-width: 300px;

        ul {
            list-style: none;

            li {
                cursor: pointer;
                outline: none !important;
                transition: none;
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    text-decoration: none !important;
                    background-color: #F5F9FA;
                    padding: 0.75rem 1rem;
                    border-radius: 0.625rem;
                    display: block;
                    color: #5E6278;

                    &:hover, &.active {
                        color: $blanco;
                        background-color: $miwuki;
                    }
                }
            }
        }
    }

    h5 {
        font-weight: 700;
    }

    .casos {
        display: flex;
        flex-direction: column;
        flex-grow: 2;

        &>div {
            display: flex;
            border-radius: 0.95rem;
            background-color: #F5F9FA;
            padding: 1rem;
            margin-bottom: 2rem;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                width: 100px;
                height: 100px;
                border-radius: 0.625rem;
            }

            &>div {
                margin-left: 1rem;

                h2 {
                    padding: 0;
                    margin: 0;
                    font-size: 1.25rem;
                    font-weight: 700;
                }
            }

            .actions {
                margin-top: auto;

                a {
                    color: $blanco;
                    margin-left: 0.85rem;

                    &:first-child { margin-left: 0; }

                    &.delete { background-color: #FF583D; }
                    &.edit { background-color: #9AD554 }
                }
            }
        }
    }
}
