.match
{
    .hero {
        border-radius: 0.625rem;
        background-color: rgba(225, 234, 238, 0.3);
        min-height: 250px;
        padding: 2rem;
        display: flex;
        align-items: flex-end;

        &>div {
            font-size: 1.15rem;
            font-weight: 400;

            p {
                margin: 0;
            }
        }
    }

    .consejo {
        border-radius: 0.475rem;
        padding: 0.2rem 0.5rem;
        background-color: $miwuki;
        color: $blanco;
        cursor: pointer;
        font-weight: 700;
        font-size: 0.7rem;
        min-width: 120px;
        text-align: center;
    }
}
