.mis-casos {
    & > div {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        background-color: #F5F9FA;
        border-radius: 1.2rem;
        margin-bottom: 1.5rem;
        padding: 1.5rem;

        &:last-child { margin-bottom: 0; }

        img {
            width: 150px;
            height: 150px;
            border-radius: 0.6rem;
        }

        .info {
            display: flex;
            flex-direction: column;
        }

        .actions {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            margin-top: auto;
        }
    }
}

.modal-body {
    .separator-text {
        margin-top: 2rem !important;
        margin-bottom: 1.5rem !important;
    }
}

#lista-interesados {
    & > div {
        margin-bottom: 1rem;
        background-color: #F5F9FA;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        padding: 0.85rem;
        cursor: pointer;

        & > div > div:nth-child(1) {
            font-weight: 700;
        }

        &:last-child { margin-bottom: 0; }

        &.selected {
            border: 2px solid $miwuki;
            background-color: #F5F9FA;
            display: flex;
            flex-direction: row;
            align-items: center;

            & > div {
                flex: 1;
            }

            &:after {
                font-family: "Font Awesome 6 Free";
                content: "\f058";
                font-weight: 900;
                font-size: 1.5rem;
                color: $miwuki;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .mis-casos {
        img {
            width: 90px !important;
            height: 90px !important;
        }
        h2 {
            margin-bottom: 0;
        }
        & > div {
            .actions {
                gap: 0 !important;
                margin-top: 0.5rem;
            }
        }
    }
}
