.banner {
    border-radius: 0.625rem;
    padding: 1.3rem;
    border: 1px solid $miwuki;


    p:last-child {
        color: $miwuki;
        border-top: 1px solid $miwuki;
        margin: 0;
        padding-top: 1.1rem;
        font-size: 1rem;
    }
}

.info-text {
    display: flex;

    .title {
        display: flex;
        align-items: center;
        background-color: $naranja;
        padding: 0.80rem 1.1rem;
        border-radius: 0.625rem 0 0 0.625rem;
        color: $blanco;

        span {
            font-weight: 700;
        }

        i {
            align-self: center;
            margin-right: 0.85rem;
            font-size: 20px;
        }
    }

    .text {
        background-color: rgba(225, 234, 238, 0.3);
        padding: 0.85rem 1.1rem;
        border-radius:  0 0.625rem 0.625rem 0;
        flex: 1;
    }
}

.mw-error {
    display: flex;
    margin-bottom: 1.5rem;
    height: 52px;
    align-items: center;

    & > div {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1rem;

        &:nth-child(1) {
            text-align: center;
            border-radius: 0.625rem 0 0 0.625rem;
            background-color: #F46C6C;
            color: $blanco;
            font-size: 20px;
        }
        &:nth-child(2) {
            border-radius: 0 0.625rem 0.625rem 0;
            background-color: #FEF0F0;
            color: #F46C6C;
            flex: 1;
            font-weight: 700;
        }
    }
}

.separator-text {
    height: 0;
    display: flex;
    align-items: center;
    border-bottom: 0;
    text-align: center;
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;

    &::before {
        content: " ";
        width: 50%;
        border-bottom: 1px solid #BBCDCE;
        margin-right: 1.25rem;
    }

    &::after {
        content: " ";
        width: 50%;
        border-bottom: 1px solid #BBCDCE;
        margin-left: 1.25rem;
    }

    span {
        width: 125px !important;
        font-weight: 700;
        font-size: 0.9rem !important;
    }
}

@media only screen and (max-width: 768px)
{
    .separator-text {
        span {
            width: 150px !important;
        }
    }
}

a {
    &.undecored {
        text-decoration: none;
        color: #91AAAB;

        &:hover {
            color: $miwuki;
        }
    }
}

i {
    &.e1 { color: #8BC34A; }
    &.e2 { color: #F44336; }
    &.e3 { color: #03A9F4; }
    &.e4 { color: #BA68C8; }
    &.e5 { color: #FFA726; }
    &.e6 { color: #009688; }
    &.e7 { color: #607D8B; }
    &.e8 { color: #263238; }
    &.e9 { color: #CFD8DC; }
    &.e10 { color: #FFDE03; }
}

.notificaciones {
    th > i {
        font-size: 1.3rem;
    }
}

.hero {
    border-radius: 0.625rem;
    background-color: rgba(225, 234, 238, 0.3);
    padding: 2rem;

    &>div {
        font-size: 1.15rem;
        font-weight: 400;

        p {
            margin: 0;
        }
    }
}

.div-flex {
    display: flex;
    flex-direction: row !important;
    gap: 1rem;
    justify-content: space-between;
}

@media only screen and (max-width: 768px)
{
    .div-flex {
        flex-direction: column !important;
    }
}
